<template>
  <div @click="triggerEdit">
    <template
      v-if="column.format === 'ownerSelect'"
    >
      <template v-if="isEditing">
        <ElSelect
          ref="select"
          filterable
          :value="rowData.wt_owner_id"
          no-match-text="Option not found"
          placeholder="Select option"
          data-test="reassign owner"
          @change="updateOwner"
        >
          <ElOptionGroup
            label="Reassign project"
          >
            <ElOption
              label="Un-assign"
              :value="null"
            />
            <ElOption
              v-for="option in users"
              :key="option.id"
              :value="option.id"
              :label="option.fullName"
              data-test="new owner name"
            />
          </ElOptionGroup>
        </ElSelect>
      </template>
      <template v-else>
        <span
          class="editable"
          data-test="owner"
        >
          {{ rowData[column.prop] || 'n/a' }}
        </span>
      </template>
    </template>
    <template v-else-if="column.format === 'date'">
      {{ formatSimpleDate(rowData[column.prop]) }}
    </template>
    <template v-else>
      {{ rowData[column.prop] || 'n/a' }}
    </template>
  </div>
</template>

<script>
  import { formatSimpleDate } from '@watchtowerbenefits/es-utils-public';

  export default {
    name: 'TableCell',
    inject: ['DashboardTable'],
    props: {
      column: {
        type: Object,
        required: true,
      },
      rowData: {
        type: Object,
        required: true,
      },
    },
    data: () => ({
      isEditing: false,
    }),
    computed: {
      /**
       * Populate options
       *
       * @returns {Array}
       */
      users() {
        return this.DashboardTable.adminUsers.map(({ id, first_name: first, last_name: last }) => ({ id, fullName: [first, last].join(' ') }));
      },
    },
    methods: {
      formatSimpleDate,
      /**
       * Update owner
       *
       * @param {number} $event
       */
      updateOwner($event) {
        this.$emit('changeProjectOwner', $event);
        this.$nextTick(() => {
          this.isEditing = false;
        });
      },
      /**
       * Trigger the isEditing mode and after the control has rendered, focus the select element.
       * This is to avoid having to click twice to open the select component.
       */
      triggerEdit() {
        this.isEditing = true;
        this.$nextTick(() => {
          this.$refs.select?.focus();
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .editable {
    color: var(--tf-blue);
    cursor: pointer;
  }
</style>
