<template>
  <div class="type-form-viewer">
    <TfTable
      find-index-by="row_number"
      :table-data="tableData"
      :table-meta="metaTableData"
    />
  </div>
</template>

<script>
  /**
   * Display legacy TypeForm data in a table
   *
   * @vuedoc
   * @exports TypeFormViewer
   * @category Components
   */
  export default {
    name: 'TypeFormViewer',
    props: {
      tableData: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        metaTableData: {
          columns: [
            {
              label: 'Title',
              prop: 'title',
            },
            {
              label: 'Value',
              prop: 'value',
            },
          ],
          emptyValue: '&mdash;',
        },
      };
    },
  };
</script>

<style lang="scss" scoped>
  .type-form-viewer {
    height: calc(100vh - 155px);
    margin-right: 15px;
    padding: 24px 54px 24px 24px;
    overflow: auto;
    word-break: break-word;
  }
</style>
