<template>
  <div class="product-details-wrapper">
    <header>
      <TfTabs
        v-model="activeTab"
        class="text-tabs"
      >
        <ElTabPane
          label="Plan"
          name="plan"
          data-test="plan"
        />
        <ElTabPane
          label="Rate"
          name="rate"
          data-test="rate"
        />
      </TfTabs>
      <!-- Status Badge -->
      <StateBadge :state="productState.current" />
      <!-- Validation button -->
      <BtnValidate
        data-test="validate button"
        :attribute-type="activeTab"
        :validation-errors="activeTab === 'plan' ? planDesignErrorCount : rateErrorCount"
        @validate="validate"
      />
    </header>
    <div class="tabs-body">
      <!-- Dental Plan Design Values -->
      <PlanDesignEntryFromTemplate
        v-if="usesPlanTemplates"
        v-show="activeTab === 'plan'"
      />
      <!-- Non-Dental Plan Design Values -->
      <PlanDesignEntry
        v-else
        v-show="activeTab === 'plan'"
      />
      <!-- Rate Attribute Values -->
      <RateEntry
        v-show="activeTab === 'rate'"
      />
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions, mapWritableState } from 'pinia';
  // Components
  import BtnValidate from '@/components/ProductPanel/BtnValidate.vue';
  import PlanDesignEntry from '@/components/ProductPanel/PlanDesignEntry.vue';
  import PlanDesignEntryFromTemplate from '@/components/PlanDesign/FromTemplate/Entry.vue';
  import RateEntry from '@/components/ProductPanel/RateEntry.vue';
  import StateBadge from '@/components/StateBadge.vue';
  // Services
  import { validatePlanDesign, validateRates as validateRatesService } from '@/services/validation.js';
  // Stores
  import { useProjectProductStore } from '@/stores/projectProduct.js';
  import { useProductStructuresStore } from '@/stores/productStructures.js';
  import { useProductSelectionsStore } from '@/stores/productSelections.js';
  import { useProductAttributesStore } from '@/stores/productAttributes.js';

  /**
   * The ProductDetails tabs and body
   *
   * @vuedoc
   * @exports ProductDetails
   * @category Components
   */
  export default {
    components: {
      BtnValidate,
      PlanDesignEntry,
      PlanDesignEntryFromTemplate,
      RateEntry,
      StateBadge,
    },
    props: {
      productState: {
        type: Object,
        default: () => ({}),
      },
    },
    data: () => ({
      activeTab: 'plan',
    }),
    computed: {
      ...mapWritableState(useProductAttributesStore, [
        'validatingRates',
        'planDesignErrorCount',
        'rateErrorCount',
        'validatingPlanDesignAttributes',
      ]),
      ...mapWritableState(useProductStructuresStore, ['validatePlanTemplate']),
      ...mapState(useProjectProductStore, [
        'usesPlanTemplates',
        'validRateStructure',
      ]),
      ...mapState(useProductSelectionsStore, ['selectedProductId']),
      ...mapState(useProductAttributesStore, [
        'attributesHidden',
      ]),
    },
    watch: {
      validatingPlanDesignAttributes: {
        async handler() {
          if (Object.keys(this.validatingPlanDesignAttributes).every((key) => !this.validatingPlanDesignAttributes[key])
            && this.planDesignErrorCount === 0) {
            await validatePlanDesign(this.selectedProductId);
          }
        },
        deep: true,
      },
      validatingRates: {
        async handler() {
          if (Object.keys(this.validatingRates).every((key) => !this.validatingRates[key])
            && this.rateErrorCount === 0) {
            await validateRatesService(this.selectedProductId);
          }
        },
        deep: true,
      },
    },
    methods: {
      ...mapActions(useProductStructuresStore, ['validatePlanDesignValues']),
      /**
       * Trigger plan value validation or rates based on the tab type.
       */
      async validate() {
        if (this.activeTab === 'plan') {
          this.planDesignErrorCount = 0;
          if (this.usesPlanTemplates) {
            this.validatePlanTemplate = true;
            this.$nextTick(async () => {
              await this.validatePlanAttributes();
            });
          } else {
            this.validatingPlanDesignAttributes = Object.fromEntries(Object.keys(this.validatingPlanDesignAttributes).map((key) => [key, !this.attributesHidden[key]]));
          }
        }

        if (this.activeTab === 'rate') {
          this.rateErrorCount = 0;
          this.validatingRates = Object.fromEntries(Object.keys(this.validatingRates).map((key) => [key, true]));
          this.$nextTick(() => {
            this.validatingRates = Object.fromEntries(Object.keys(this.validatingRates).map((key) => [key, false]));
          });
        }
      },
      /**
       * @ async
       * Validate attributes
       */
      async validatePlanAttributes() {
        try {
          this.planDesignErrorCount += await this.validatePlanDesignValues();

          if (!this.planDesignErrorCount) {
            await validatePlanDesign(this.selectedProductId);
          }
          this.validatePlanTemplate = false;
        } catch {
          this.displayToast({
            message: 'There was an error validating the plan attributes.',
          });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  header {
    padding: 10px;
    box-shadow: inset 0 -3px 0 0 var(--tf-blue);
    background-color: var(--tf-gray-light);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .product-details-wrapper,
  .tabs-body {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
</style>
