import axios from 'axios';
import { getAxiosConfig } from '@watchtowerbenefits/es-utils-public';
import { config } from '@/utils/config.js';

const apiUrl = `${config.VUE_APP_API_URL}/v1/admin`;

/**
 * Project API calls
 *
 * @exports File
 * @category Services
 */
export default {
  /**
   * Delete source
   *
   * @param {string} sourceId
   * @returns {Promise}
   */
  deleteSource(sourceId) {
    return new Promise((resolve, reject) => {
      const axiosConfig = getAxiosConfig(config.VUE_APP_COOKIE_NAMESPACE);
      const url = `${apiUrl}/sources/${sourceId}`;

      axios
        .delete(url, axiosConfig)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * Get Info related to specific Document
   *
   * @param {number} documentId
   * @returns {Promise}
   */
  getDocumentSources(documentId) {
    return new Promise((resolve, reject) => {
      const axiosConfig = getAxiosConfig(config.VUE_APP_COOKIE_NAMESPACE);
      const url = `${apiUrl}/documents/${documentId}/sources`;

      axios
        .get(url, axiosConfig)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * Get JSON/Image asset details for a source
   *
   * @param {string} sourceId
   * @returns {Promise}
   */
  getSourceAssets(sourceId) {
    return new Promise((resolve, reject) => {
      const axiosConfig = getAxiosConfig(config.VUE_APP_COOKIE_NAMESPACE);
      const url = `${apiUrl}/sources/${sourceId}/assets`;

      axios
        .get(url, axiosConfig)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * Pass details for upload to FileManager so all API calls are still in Project.js
   *
   * @param {string} documentId
   * @returns {Promise}
   */
  getSourceUploadDetails(documentId) {
    const axiosConfig = getAxiosConfig(config.VUE_APP_COOKIE_NAMESPACE);

    return {
      action: `${apiUrl}/documents/${documentId}/upload_sources`,
      headers: axiosConfig.headers,
    };
  },
  /**
   * Get annotations for a specific source
   *
   * @param {number} sourceId
   * @returns {Promise}
   */
  getSourceAnnotations(sourceId) {
    return new Promise((resolve, reject) => {
      const url = `${apiUrl}/smart_parser/sources/${sourceId}/annotations`;
      const axiosConfig = getAxiosConfig(config.VUE_APP_COOKIE_NAMESPACE);

      axios
        .get(url, axiosConfig)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * Get the page JSON for a source
   *
   * @param {string} sourceUrl
   * @returns {Promise}
   */
  getPageMarkup(sourceUrl) {
    return new Promise((resolve, reject) => {
      axios
        .get(sourceUrl)
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * Restart the conversion of source file
   *
   * @param {string} sourceId
   * @returns {Promise}
   */
  reprocessSource(sourceId) {
    return new Promise((resolve, reject) => {
      const axiosConfig = getAxiosConfig(config.VUE_APP_COOKIE_NAMESPACE);
      const url = `${apiUrl}/sources/${sourceId}/retry_conversion`;

      axios
        .patch(url, {}, axiosConfig)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * Change the "name" attribute of a source
   *
   * @param {object} sourceId
   * @param {string} name
   * @returns {Promise}
   */
  setSourceName(sourceId, name) {
    return new Promise((resolve, reject) => {
      const axiosConfig = getAxiosConfig(config.VUE_APP_COOKIE_NAMESPACE);
      const url = `${apiUrl}/sources/${sourceId}`;
      const data = { name };

      axios
        .patch(url, data, axiosConfig)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * Change the "category" attribute of a source
   *
   * @param {object} sourceId
   * @param {string} sourceType
   * @returns {Promise}
   */
  setSourceType(sourceId, sourceType) {
    return new Promise((resolve, reject) => {
      const axiosConfig = getAxiosConfig(config.VUE_APP_COOKIE_NAMESPACE);
      const url = `${apiUrl}/sources/${sourceId}`;
      const data = { type: sourceType };

      axios
        .patch(url, data, axiosConfig)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   *
   * Get New RFP Responses TypeForm data
   *
   * @param {number} projectId
   * @returns {Promise}
   */
  getNewRfpResponses(projectId) {
    return new Promise((resolve, reject) => {
      const axiosConfig = getAxiosConfig(config.VUE_APP_COOKIE_NAMESPACE);
      const url = `${apiUrl}/projects/${projectId}/client_rfp_setup_responses`;

      axios
        .get(url, axiosConfig)
        .then((response) => {
          resolve(response.data.client_rfp_setup_responses);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * Get Inforce Carrier RFI Responses TypeForm data
   *
   * @param {number} projectId
   * @returns {Promise}
   */
  getInforceRfiResponses(projectId) {
    return new Promise((resolve, reject) => {
      const axiosConfig = getAxiosConfig(config.VUE_APP_COOKIE_NAMESPACE);
      const url = `${apiUrl}/projects/${projectId}/inforce_rfi_responses`;

      axios
        .get(url, axiosConfig)
        .then((response) => {
          resolve(response.data.carrier_rfi_responses);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};
