<template>
  <div
    v-loading="!isLoaded"
  >
    <LfcModal
      :title="undoJobs.length > 1 ? 'Are you sure you want to undo setups for all items?' : 'Are you sure you want to undo setup for this item?'"
      :subtitle="''"
      :visible.sync="modalVisible"
      :show-close="true"
      @closed="cancelChanges"
    >
      <p>This will remove all relevant annotations, and revert the attribute value fields back to blank. </p>
      <template #footer>
        <AppButton
          data-test="cancel btn"
          type="secondary"
          size="large"
          text="Cancel"
          @click="cancelChanges"
        />
        <AppButton
          data-test="done button"
          :is-disabled="isSaving"
          type="primary"
          icon="fa-solid fa-check"
          text="Confirm"
          @click="saveChanges"
        />
      </template>
    </LfcModal>
    <div class="jobs-table-header">
      <h4>Setup history</h4>
      <div
        v-if="jobs.length > 1"
        class="undo-button btn-group align-end"
      >
        <AppButton
          data-test="undo all btn"
          :prepend="true"
          type="decline"
          size="text-small"
          icon="fa-solid fa-undo"
          text="Undo all"
          @click="confirmUndoAllJobs"
        />
      </div>
    </div>

    <div class="jobs-table">
      <TfTable
        :table-data="jobTableData"
        :table-meta="jobTableMeta"
      >
        <template #parentRow="{ rowData }">
          <TfTableParentRow
            v-bind="{ rowData }"
            data-test="table row"
          >
            <TfTableTd
              data-test="table-cell undo"
            >
              <AppButton
                class="undo-button no-border"
                type="decline"
                size="icon"
                icon="fa-solid fa-undo"
                @click="confirmUndoSearchJob(rowData.id)"
              />
            </TfTableTd>
            <TfTableTd
              data-test="table-cell carrier"
            >
              {{ rowData.carrierName }}
            </TfTableTd>
            <TfTableTd
              data-test="table-cell product"
            >
              {{ rowData.productName }}
            </TfTableTd>
            <TfTableTd
              data-test="table-cell status"
            >
              {{ rowData.status }}
            </TfTableTd>
          </TfTableParentRow>
        </template>
        <template #empty>
          No search jobs were found for this document.
        </template>
      </TfTable>
    </div>
  </div>
</template>

<script>
  import {
    formatDateAndTime,
    formatSimpleDate,
    sortObjects,
  } from '@watchtowerbenefits/es-utils-public';
  // vue
  import {
    mapActions,
    mapState,
  } from 'pinia';
  import { useTacticEngineStore } from '@/stores/tacticEngine.js';
  import { deleteSearchJob } from '@/services/tacticEngine.js';

  export default {
    name: 'JobTable',
    data() {
      return {
        isLoaded: true,
        isSaving: false,
        modalVisible: false,
        undoJobs: [],
        jobTableMeta: {
          columns: [
            {
              prop: 'undo',
              label: '',
              format: 'undo',
              width: '20',
            },
            {
              prop: 'carrierName',
              label: 'Carrier',
              format: 'carrier',
            },
            {
              prop: 'productName',
              label: 'Product name',
              format: 'product',
            },
            {
              prop: 'status',
              label: 'Status',
              format: 'status',
              width: '80',
            },
          ],
          childColumns: {
            jobChildDataSet: [
              {
                prop: 'id',
                label: 'Job ID',
                width: 83,
              },
              {
                prop: 'containerName',
                label: 'Class/plan name',
                width: 220,
              },
              {
                prop: 'jobInitiated',
                label: 'Job initiated',
                width: 150,
              },
            ],
          },
        },
      };
    },
    computed: {
      ...mapState(useTacticEngineStore, ['jobs']),
      /**
       * Formats the jobs into table data
       *
       * @returns {object} the formatted tableData
       */
      jobTableData() {
        const tableData = this.jobs.map((job) => ({
          id: job.id,
          carrierName: job.carrier.name,
          productName: job.product_type_name,
          status: job.state,
          children: [{
            columnKey: 'jobChildDataSet',
            data: [{
              id: job.id,
              containerName: `${job.project_products_container_type} ${job.project_products_container_name}: ${job.project_products_container_description}`,
              jobInitiated: formatDateAndTime(job.created_at),
            }],
          }],
        }));

        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        return sortObjects(
          tableData,
          'id',
          'desc',
        );
      },
    },
    methods: {
      ...mapActions(useTacticEngineStore, ['setJobs']),
      formatSimpleDate,
      /**
       * Close modal and confirm changes are canceled with a notification
       */
      cancelChanges() {
        this.modalVisible = false;
        this.undoJobs = [];
      },
      /**
       * Close modal and confirm changes are canceled with a notification
       */
      saveChanges() {
        this.isSaving = true;
        const promiseArray = [];

        this.undoJobs.forEach((jobId) => {
          promiseArray.push(this.deleteJob(jobId));
        });

        Promise.all(promiseArray).then(() => {
          this.modalVisible = false;
          this.undoJobs = [];
          this.isSaving = false;
          this.$emit('jobsCanceled');
        })
          .catch(() => {
            this.displayToast({
              message: 'There was an error cancelling the changes.',
            });
          });
      },
      /**
       * Delete a job
       *
       * @param {number} jobId
       * @returns {Promise}
       */
      deleteJob(jobId) {
        return deleteSearchJob(jobId)
          .then(() => {
            this.setJobs(this.jobs.filter(({ id }) => id !== jobId));
          })
          .catch(() => {
            this.displayToast({
              message: 'There was an error undoing a job.',
            });
          });
      },
      /**
       * Show a confirm modal before undoing a search job
       *
       * @param {number} jobId
       */
      confirmUndoSearchJob(jobId) {
        this.undoJobs.push(jobId);
        this.modalVisible = true;
      },
      /**
       * Show a confirm modal before undoing all search jobs
       */
      confirmUndoAllJobs() {
        this.jobs.forEach(({ id }) => this.undoJobs.push(id));
        this.modalVisible = true;
      },
    },
  };
</script>

<style lang="scss" scoped>
.jobs-table-header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  h4 {
    font-size: 16px;
    margin-bottom: 20px;
  }
}

.undo-button button {
  margin-right: 10px;
}
</style>

<style lang="scss">
.jobs-table .table-parent {
  th, td {
    padding: 10px;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
  }
}
</style>
