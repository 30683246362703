import Vue from 'vue';
import { defineStore } from 'pinia';
import ProductService, {
  getProductAnnotations,
} from '@/services/product.js';
import { useProjectProductStore } from '@/stores/projectProduct.js';
import { useProductAttributesStore } from '@/stores/productAttributes.js';
import { useProductSelectionsStore } from '@/stores/productSelections.js';
import { useProductStructuresStore } from '@/stores/productStructures.js';
import { useAnnotationsStore } from '@/stores/annotations.js';

export const useProductContainersStore = defineStore('productContainers', {
  state: () => ({
    containerTypeSelection: null,
    currentContainers: [],
    isProductContainersLoaded: false,
  }),
  getters: {
    /**
     * Determines if containerTypeSelection equals 'class'
     *
     * @param {object} state
     * @returns {boolean}
     */
    isClassBased: (state) => state.containerTypeSelection?.toLowerCase() === 'class',
    /**
     * Determines if there are no or missing containers with descriptions in currentContainers
     *
     * @param {object} state
     * @returns {boolean}
     */
    missingContainers: (state) => (
      !state.currentContainers.length
      || state.currentContainers.filter(({ description }) => !description).length > 0
    ),
  },
  actions: {
    /**
     * Adds product container to currentContainers.
     *
     * @param {object} payload
     * @param {boolean} payload.addToStore
     * @param {number} payload.containerTypeId
     * @param {number} payload.productId
     * @returns {object}
     */
    async addProductContainer({
      addToStore = true,
      containerTypeId = 1,
      productId,
    }) {
      const { container } = await ProductService.addProductContainer(
        {
          data: { project_products_container_type_id: containerTypeId },
          productId,
        },
      );
      const newContainer = {
        ...container,
        description: null,
        state: 'editing',
      };

      // Plan Based products are already in the store so don't add them again.
      if (addToStore) {
        this.currentContainers.push(newContainer);
      }

      return newContainer;
    },
    /**
     * Deletes product container from currentContainers.
     *
     * @param {object} payload
     * @param {object} payload.container
     * @param {number} payload.projectProductId
     * @returns {object}
     */
    async deleteProductContainer({ container, projectProductId }) {
      const containerIndex = this.currentContainers.findIndex((currentContainer) => currentContainer.id === container.id);

      this.setContainerState({
        container,
        containerState: 'deleting',
      });

      try {
        if (container.newId) {
          this.currentContainers.splice(containerIndex, 1);

          return containerIndex;
        }

        const data = await ProductService.deleteContainer({ containerId: container.id });
        const projectProductStore = useProjectProductStore();

        projectProductStore.deleteActiveProductContainer({
          projectProductId,
          containerIndex,
        });

        return data;
      } catch (error) {
        this.setContainerState({
          container,
          containerState: 'editing',
        });

        throw error;
      }
    },
    /**
     * Sets a containers state in currentContainers.
     *
     * @param {object} payload
     * @param {object} payload.container
     * @param {string} payload.containerState
     */
    setContainerState({ container, containerState }) {
      const containerIndex = this.currentContainers.findIndex((currentContainer) => currentContainer.id === container.id);

      Vue.set(this.currentContainers[containerIndex], 'state', containerState);
    },
    /**
     * Sets currentContainers to new currentContainers.
     *
     * @param {Array} currentContainers
     */
    setCurrentContainers(currentContainers) {
      const containers = currentContainers.map((container) => {
        let associatedTierGroupId;

        if (container.container_type_id === 3) {
          associatedTierGroupId = 7;
        }

        if (container.container_type_id === 4) {
          associatedTierGroupId = 8;
        }

        return { associatedTierGroupId, ...container };
      }).sort((a, b) => a.position - b.position);

      this.currentContainers = containers;
    },
    /**
     * Updates a container in currentContainers.
     *
     * @param {object} payload
     * @param {object} payload.container
     * @param {object} payload.data
     * @param {number} payload.projectProductId
     * @param {boolean} payload.reordering
     * @returns {object}
     */
    async updateProductContainer({
      container,
      data,
      projectProductId,
      reordering = false,
    }) {
      const containerIndex = this.currentContainers.findIndex((currentContainer) => currentContainer.id === container.id);

      // Reordering sets the loading state in component
      if (!reordering) {
        this.setContainerState({
          container,
          containerState: 'saving',
        });
      }

      try {
        const { container: updatedContainer } = await ProductService.updatePlanContainer({ containerId: container.id, data });
        const projectProductStore = useProjectProductStore();
        const containerWithAssociatedTierGroupId = projectProductStore.usesPlanTemplates
          ? {
            ...updatedContainer,
            associatedTierGroupId: container.associatedTierGroupId,
          } : updatedContainer;

        if (!reordering) {
          Vue.set(this.currentContainers, containerIndex, containerWithAssociatedTierGroupId);
          this.setContainerState({
            container: containerWithAssociatedTierGroupId,
            containerState: 'reviewing',
          });
          projectProductStore.updateActiveProjectProductContainer({
            projectProductId,
            container: containerWithAssociatedTierGroupId,
            containerIndex,
          });
        }

        return containerWithAssociatedTierGroupId;
      } catch (error) {
        if (!reordering) {
          this.setContainerState({
            container,
            containerState: 'editing',
          });
        }

        throw error;
      }
    },
    async syncProductDetails(emitDetails = {}) {
      const { newId } = emitDetails;
      let containerType = null;
      let product;
      let tierGroups;

      this.isProductContainersLoaded = false;

      const productSelectionsStore = useProductSelectionsStore();
      const projectProductsStore = useProjectProductStore();
      const productStructuresStore = useProductStructuresStore();

      productSelectionsStore.resetProductInfo();

      // annotations are not necessary to resolve tier group stuff
      try {
        const { annotations } = await getProductAnnotations(projectProductsStore.selectedProjectProduct.inforce_product.id);
        const annotationsStore = useAnnotationsStore();

        annotationsStore.setAnnotations(annotations);
      } catch {
        throw new Error('There was an error getting the product annotations.');
      }

      try {
        product = await ProductService
          .getProductDetails(projectProductsStore.selectedProjectProduct.inforce_product.id);
      } catch {
        throw new Error('There was an error getting the product details.');
      }

      // API call to return tier group data
      try {
        const { tier_groups } = await ProductService
          .getAvailableTierGroups(
            projectProductsStore.selectedProjectProduct.product_type_id,
          );

        tierGroups = tier_groups;

        productStructuresStore.availablePlanDesignTierGroups = tierGroups.plan_design_tier_groups;
        productStructuresStore.availableRateTierGroups = tierGroups.rate_tier_groups;
      } catch {
        throw new Error('There was an error getting the tier groups.');
      }

      // We need to make sure that the available tier groups are created first since
      // some plans rely on a subtypes to be present.
      let containers;

      productSelectionsStore.setActiveProjectProduct(product);

      if (projectProductsStore.usesPlanTemplates) {
        productStructuresStore.planDesignCategories = [];
      }

      if (product.project_products_containers.length) {
        containerType = product.project_products_containers[0].container_type;
        // We need to create a new mapping of containers here b/c
        // containers are coming back w/ a `description` that we null out for new containers.
        containers = product.project_products_containers.map((container) => {
          const newContainer = newId === container.id;
          const defaultDescription = container.description && container.description.toLowerCase() === 'not provided';

          return {
            ...container,
            description: newContainer || defaultDescription || !container.description
              ? null
              : container.description,
            state: newContainer || defaultDescription || !container.description
              ? 'editing'
              : 'reviewing',
          };
        });

        this.setCurrentContainers(containers);
      } else {
        // there is a potential bug here where alternative products are created w/o any containers.
        // This happens even after a product has been validate.
        containerType = this.containerTypeSelection;
      }

      const tempPlans = containerType.toLowerCase() === 'plan'
        ? productSelectionsStore.availableProductTypes.find(
          (productType) => productType.id === projectProductsStore.selectedProjectProduct?.product_type_id,
        )?.container_types
        : [];

      productStructuresStore.availablePlans = tempPlans;

      const productAttributesStore = useProductAttributesStore();

      productAttributesStore.setCategoriesAndAttributes({
        product,
        containers,
        tierGroups: tierGroups.plan_design_tier_groups,
        usePlanTemplates: projectProductsStore.usesPlanTemplates,
      });
      // TODO find default container type
      this.containerTypeSelection = containerType;
      projectProductsStore.updateProjectProductsValidation({
        projectProduct: product.project_product,
      });
      this.isProductContainersLoaded = true;
    },
  },
});
