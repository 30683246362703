<template>
  <div
    class="file-viewer"
  >
    <template
      v-if="isLoaded"
    >
      <FileTabs />
      <template
        v-if="projectHasActiveFile"
      >
        <!-- pdf -->
        <PdfViewer
          v-if="activeFileType === 'source'"
        />

        <!-- broker info -->
        <template v-if="activeFileType === 'broker-info'">
          <TypeFormViewer
            v-if="typeFormData.length"
            :table-data="typeFormData"
          />
          <ClientSetupInfo v-else />
        </template>

        <NpcDetailsViewer v-if="activeFileType === 'npc-request-details'" />

        <!-- rfi -->
        <TypeFormViewer
          v-if="activeFileType === 'rfi'"
          :table-data="typeFormData"
        />
      </template>
    </template>
  </div>
</template>

<script>
  import { mapState, mapActions, mapWritableState } from 'pinia';
  import { useFilesStore } from '@/stores/files.js';
  import { useFileViewerStore } from '@/stores/fileViewer.js';
  import { useProjectStore } from '@/stores/project.js';
  // services
  import FileService from '@/services/file.js';
  // components
  import FileTabs from '@/components/FileViewer/FileTabs.vue';
  import PdfViewer from '@/components/FileViewer/PdfViewer/index.vue';
  import TypeFormViewer from '@/components/FileViewer/TypeFormViewer.vue';
  import ClientSetupInfo from '@/components/FileViewer/ClientSetupInfo/index.vue';

  /**
   * FileViewer base component
   *
   * @vuedoc
   * @exports FileViewer
   * @category Components
   */
  export default {
    name: 'FileViewer',
    components: {
      ClientSetupInfo,
      FileTabs,
      PdfViewer,
      TypeFormViewer,
      NpcDetailsViewer: () => import('@/components/FileViewer/NpcDetails.vue'),
    },
    props: {
      projectId: {
        type: [Number, String],
        default: null,
      },
    },
    computed: {
      ...mapState(useFilesStore, [
        'inforceRfiResponses',
        'newRfpResponses',
        'typeFormsLoaded',
      ]),
      ...mapState(useFileViewerStore, [
        'getActiveFileId',
        'goToAnnotation',
        'openFileIds',
      ]),
      ...mapWritableState(useFileViewerStore, ['requestTabFocus']),
      ...mapState(useProjectStore, ['currentProject', 'documentId']),
      /**
       * Determine if all required data is loaded
       *
       * @returns {boolean}
       */
      isLoaded() {
        return this.currentProject?.id
          && this.typeFormsLoaded;
      },
      /**
       * Determine if any file is/was currently being viewed in the file-viewer
       *
       * @returns {boolean}
       */
      projectHasActiveFile() {
        // when the FileTabs component initializes and there's no tabs,
        // it seems to hit the activeFileId setter with '0' to signify
        // nothing is open. However, this breaks this check since we
        // previously assumed `null` meant no files open.
        return !!this.getActiveFileId && this.getActiveFileId !== '0';
      },
      /**
       * Determine if this is a PDF (source), one of either types of type-forms, or, a newer 'file' format.
       *
       * @returns {string}
       */
      activeFileType() {
        if (Number(this.getActiveFileId)) {
          return 'source';
        }

        if (this.getActiveFileId.includes('inforce-rfi-responses')) {
          return 'rfi';
        }

        if (this.getActiveFileId.includes('npc-request-details')) {
          return 'npc-request-details';
        }

        return 'broker-info';
      },
      /**
       * Homogenize the data from two kinds of TypeForms so that we can render a table
       *
       * @returns {Array}
       */
      typeFormData() {
        if (this.activeFileType === 'rfi' && this.getActiveFileId.includes('inforce-rfi-responses')) {
          const carrierResponse = this.inforceRfiResponses[this.getActiveFileId];

          return carrierResponse
            ? carrierResponse.responses
            : [];
        }

        return this.newRfpResponses
          ? this.newRfpResponses.responses
          : [];
      },
    },
    watch: {
      documentId: {
        /**
         * loads files and sets document id when document id changes
         *
         * @param {number} documentId
         */
        handler(documentId) {
          if (documentId) {
            this.loadFiles();
          }
        },
        immediate: true,
      },
      goToAnnotation: {
        /**
         * focuses on specific tab if annotation is given
         *
         * @param {object} annotation
         */
        handler(annotation) {
          if (annotation) {
            this.requestTabFocus = annotation.source_id;
          }
        },
      },
      immediate: true,
    },
    created() {
      if (!this.openFileIds[this.projectId]) {
        this.createOpenFilesForProjectId(this.projectId);
      }
    },
    methods: {
      ...mapActions(useFilesStore, [
        'setInforceRfiResponses',
        'setNewRfpResponses',
        'setSources',
      ]),
      ...mapActions(useFileViewerStore, ['createOpenFilesForProjectId']),
      /**
       * Load real sources, and fetch TypeForm data to track as pseudo-sources
       */
      async loadFiles() {
        try {
          const { sources } = await FileService.getDocumentSources(this.documentId);

          this.setSources(sources);
        } catch {
          this.displayToast({
            message: 'There was an error retrieving the list of documents.',
          });
        }

        try {
          const newRfpResponses = await FileService.getNewRfpResponses(this.projectId);

          this.setNewRfpResponses(newRfpResponses);
        } catch {
          this.displayToast({
            message: 'There was an error retrieving the list of RFPs.',
          });
        }

        try {
          const inforceRfiResponses = await FileService.getInforceRfiResponses(this.projectId);

          this.setInforceRfiResponses(inforceRfiResponses);
        } catch {
          this.displayToast({
            message: 'There was an error retrieving the list of RFIs.',
          });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .file-viewer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
  }
</style>
